import axios from 'axios';
import { Todo } from '../types/todo';
import { authService } from './authService';

const API_URL = 'https://new.petleshkov.com:3002/api';


const getAuthHeaders = () => ({
  headers: { 
    'Content-Type': "application/json",
    Authorization: `Bearer ${authService.getToken()}`
  }
});

export const todoService = {
  async getAllTodos() {
    const response = await axios.get<Todo[]>(`${API_URL}/todos`, getAuthHeaders());
    console.log(response)
    return response.data;
  },

  async createTodo(todo: Omit<Todo, 'id'>) {
    const response = await axios.post<Todo>(`${API_URL}/todos`, todo, getAuthHeaders());
    return response.data;
  },

  async updateTodo(id: number, todo: Partial<Todo>) {
    const response = await axios.put<Todo>(`${API_URL}/todos/${id}`, todo, getAuthHeaders());
    return response.data;
  },

  async deleteTodo(id: number) {
    await axios.delete(`${API_URL}/todos/${id}`, getAuthHeaders());
  }
};