import axios from 'axios';

const API_URL = 'https://new.petleshkov.com/api';

export const authService = {
  async login(email: string, password: string) {
    const response = await axios.post(`${API_URL}/auth/login`, { email, password });
    const { token } = response.data;
    localStorage.setItem('token', token);
    return token;
  },

  async register(email: string, password: string, name: string) {
    const response = await axios.post(`${API_URL}/auth/register`, { email, password, name });
    const { token } = response.data;
    localStorage.setItem('token', token);
    return token;
  },

  logout() {
    localStorage.removeItem('token');
  },

  getToken() {
    return localStorage.getItem('token');
  }
};